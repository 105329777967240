import { Constants } from './enums'
import Hashkit from 'hashkit'

const hashLength = 6
const hashKit = new Hashkit({
  padding: hashLength,
  mask: true,
  seed: Constants['VITE_SALT_FOR_HASHIDS']
})
export function getHashedId(id: number): string {
  return hashKit.encode(id)
}
export function extractIdFromHash(hash: string): number {
  const result = hashKit.decode(hash)
  if (isNaN(result) || !isFinite(result)) {
    throw new Error('EncryptedId is invalid')
  }
  return result
}
