export const Constants = {
  // BASE_URL: 'https://conversation-demo-api.onrender.com/',
  BASE_URL: import.meta.env.VITE_BASE_URL,
  ENV_NAME: import.meta.env.VITE_ENV_NAME,
  VITE_SALT_FOR_HASHIDS: import.meta.env.VITE_SALT_FOR_HASHIDS,
  VITE_SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN
} as const
export type Constants = keyof typeof Constants

export const colorPalette: Record<string, Record<string, string>> = {
  service: {
    black: '#000000',
    lighter: '#343434',
    secondary: '#787878',
    chatgpt: '#343541' /* ChatGPT */,
    accent: '#005792'
  },
  bg: {
    white: '#FFFFFF',
    gray01: '#F8F8F8',
    gray02: '#FAFAF6' /* baige */,
    gray04: '#708090',
    gray07: '#2C3539',
    gray08: '#353839',
    gray08_5: 'rgba(53, 56, 57, 0.5)',
    black_5: 'rgba(0, 0, 0, 0.5)',
    black: '#000000'
  },
  text: {
    base: '#333333',
    lighter: '#787878',
    lighter2: '#a0a0a0',
    black: '#000000',
    white: '#FFFFFF',
    disabled: '#9E9E9E'
  },
  border: {
    base: '#E8E8E8',
    active: '#708090',
    gray08: '#353839',
    fPale: 'rgba(255,255,255,0.25)'
  },
  shadow: {
    base: 'rgba(255,255,255, 0.2)',
    card: '0 1px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14)'
  },
  utility: {
    green: '#007F5F', // success, done
    greenLighter: '#7FBFAF',
    greenBg: '#E5F2EF',
    yellow: '#F77F00', // caution
    yellowBg: '#FEF2E5',
    red: '#D62828', // danger, warning, failure
    redBg: '#FAE9E9'
  }
}

export const SettingsMenuItems = {
  myAccount: 'ユーザアカウント',
  users: 'ユーザ管理',
  spaces: 'スペース管理',
  concierge: 'コンシェルジュ設定',
  weaviateClass: 'Weaviateスキーマ管理',
  myDataAnalyst: 'MyDataAnalyst管理'
} as const
export type SettingsMenuItems = keyof typeof SettingsMenuItems

export const ConciergeSide = {
  user: 'user',
  operator: 'operator'
} as const
export type ConciergeSide = keyof typeof ConciergeSide
