import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/authStore'
import { extractIdFromHash } from '@/utils/hash.helper'
const PlaygroundView = () => import('@/views/PlaygroundView.vue')
const ThreadsView = () => import('@/views/ThreadsView.vue')
const SelfAppConfigView = () => import('@/views/SelfAppConfigView.vue')
const LoginView = () => import('@/views/LoginView.vue')
const TokenValidatorView = () => import('@/views/TokenValidatorView.vue')
const SettingsView = () => import('@/views/SettingsView.vue')
const MonitoringView = () => import('@/views/MonitoringView.vue')
const ServiceView = () => import('@/views/ServiceView.vue')
const UrlSchemeView = () => import('@/views/UrlSchemeView.vue')
const HubView = () => import('@/views/HubView.vue')
const StoreView = () => import('@/views/StoreView.vue')
const CharacterChatView = () => import('@/views/CharacterChatView.vue')
import { _ElMessage } from '@/utils/element-plus-wrapper'
import type { SettingsMenuItems } from '@/utils/enums'
import type { OnetimeTokenActionType } from '@/types/auth.type'
import { useUserStore } from '@/stores/userStore'
import type { MyAccountEditTarget } from '@/components/settings/MyAccount.vue'
import type { UrlSchemeName } from '@/views/UrlSchemeView.vue'
import type { TabName } from '@/views/ServiceView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      props: (route: { query: { redirect?: string; authError?: boolean } }) => ({
        redirect: route.query.redirect,
        authError: route.query.authError
      }),
      meta: { requiresAuth: false, requiresCurrentSpace: false }
    },
    {
      path: '/link',
      name: 'urlScheme',
      component: UrlSchemeView,
      props: (route: { query: { name: UrlSchemeName; id: string } }) => ({
        name: route.query.name,
        id: route.query.id
      }),
      meta: { requiresAuth: true, requiresCurrentSpace: false }
    },
    {
      path: '/validates',
      name: 'tokenValidator',
      component: TokenValidatorView,
      props: (route: { query: { token: string; actionType: OnetimeTokenActionType } }) => ({
        token: route.query.token,
        actionType: route.query.actionType
      }),
      meta: { requiresAuth: false }
    },
    {
      path: '/spaces/:spaceIdHash/store',
      name: 'store',
      component: StoreView,
      props: true,
      meta: { requiresAuth: true, requiresCurrentSpace: true }
    },
    {
      path: '/spaces/:spaceIdHash/character-chat/:character',
      name: 'characterChat',
      component: CharacterChatView,
      props: true,
      meta: { requiresAuth: true, requiresCurrentSpace: true }
    },
    {
      path: '/spaces/:spaceIdHash/playground',
      name: 'playground',
      component: PlaygroundView,
      props: true,
      meta: { requiresAuth: true, requiresCurrentSpace: true }
    },
    {
      path: '/spaces/:spaceIdHash/hub',
      name: 'hub',
      component: HubView,
      props: true,
      meta: { requiresAuth: true, requiresCurrentSpace: true }
    },
    {
      path: '/spaces/:spaceIdHash/threads/:skill',
      name: 'threads',
      component: ThreadsView,
      props: (route: {
        params: { spaceIdHash: string; skill: string }
        query: { configId?: string }
      }) => ({
        skill: route.params.skill,
        configId: route.query.configId,
        spaceIdHash: route.params.spaceIdHash
      }),
      meta: { requiresAuth: true, requiresCurrentSpace: true }
    },
    {
      path: '/spaces/:spaceIdHash/appConfigs/:skillName/:configId',
      name: 'selfAppConfig',
      component: SelfAppConfigView,
      props: true,
      meta: { requiresAuth: true, requiresCurrentSpace: true }
    },
    {
      path: '/spaces/:spaceIdHash/monitoring',
      name: 'monitoring',
      component: MonitoringView,
      props: (route: { params: { spaceIdHash: string }; query: { id?: string } }) => ({
        threadIdHash: route.query.id,
        spaceIdHash: route.params.spaceIdHash
      }),
      meta: { requiresAuth: true, requiresCurrentSpace: true }
    },
    {
      path: '/spaces/:spaceIdHash/service',
      name: 'service',
      component: ServiceView,
      props: (route: {
        query: {
          ticketId?: string
          threadId?: string
          userId?: string
          selectedSpaceId?: string
          tabName?: TabName
        }
        params: { spaceIdHash: string }
      }) => ({
        spaceIdHash: route.params.spaceIdHash,
        ticketId: route.query.ticketId,
        threadId: route.query.threadId,
        userId: route.query.userId,
        selectedSpaceId: route.query.selectedSpaceId,
        tabName: route.query.tabName
      }),
      meta: { requiresAuth: true, requiresCurrentSpace: true }
    },
    {
      path: '/spaces/:spaceIdHash/settings',
      name: 'settings',
      component: SettingsView,
      props: (route: {
        query: { menu?: SettingsMenuItems; resourceId?: string; edit?: MyAccountEditTarget }
        params: { spaceIdHash: string }
      }) => ({
        spaceIdHash: route.params.spaceIdHash,
        menu: route.query.menu,
        resourceId: route.query.resourceId,
        myAccountEdit: route.query.edit
      }),
      meta: { requiresAuth: true, requiresCurrentSpace: true }
    }
  ]
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()
  if (!to.meta.requiresAuth) {
    next()
  } else {
    if (authStore.is_loggedin) {
      mainGuard()
    } else {
      // valid cookie持ってるかチェック
      authStore
        .authedHello({ redirect: to.fullPath })
        .then(() => {
          mainGuard()
        })
        .catch(() => {
          redirectToHome('再ログインしてください')
        })
    }
  }

  function mainGuard() {
    if (to.meta.requiresCurrentSpace) {
      // decode spaceId
      const { spaceId, hashedSpaceId } = (() => {
        try {
          const hashedSpaceId = to.fullPath.split('/')[2]
          return {
            spaceId: extractIdFromHash(hashedSpaceId),
            hashedSpaceId
          }
        } catch (err) {
          return {
            spaceId: -1,
            hashedSpaceId: ''
          }
        }
      })()

      // check
      if (spaceId !== authStore.current_space.id) {
        redirectToHome('アクセス権限がありません') // supplierでも一旦弾いておく
      } else {
        const userStore = useUserStore()
        // pw
        if (userStore.get_current_user.requiredUpdatePassword) {
          if (
            to.name == 'settings' &&
            to.query.menu == 'myAccount' &&
            to.query.edit == 'password'
          ) {
            // avoid infinite loop
            next()
          } else {
            _ElMessage({
              type: 'error',
              message: 'パスワードの更新が必要です'
            })
            next({
              path: `/spaces/${hashedSpaceId}/settings`,
              query: { menu: 'myAccount', edit: 'password' }
            })
          }
        }
        next()
      }
    } else {
      next()
    }
  }

  function redirectToHome(msg: string) {
    _ElMessage({ type: 'error', message: msg })
    next({
      path: '/',
      query: { redirect: to.fullPath }
    })
  }
})

export default router
